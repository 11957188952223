import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth,  GoogleAuthProvider,  indexedDBLocalPersistence, initializeAuth, User} from 'firebase/auth';
import { getDatabase, ref, get, update, push } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { connect } from "react-redux";
import { getStorage } from "@firebase/storage";
import { authActions } from "./modules/authentication/store/actions";
import { isPlatform } from "@ionic/react";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
    apiKey: "AIzaSyCCroiw7dBvU1A3vNMDEuEAkYh2n2op48k",
    authDomain: "t4a-internal.firebaseapp.com",
    databaseURL: "https://t4a-internal-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "t4a-internal",
    storageBucket: "t4a-internal.appspot.com",
    messagingSenderId: "203829265274",
    appId: "1:203829265274:web:c25de856251f456e9d8cc3",
    measurementId: "G-YPT95BDB1J"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);


export const auth =
	isPlatform("ios") ?
		initializeAuth(app, {
			persistence: indexedDBLocalPersistence
		}) :
		getAuth();
/* auth.languageCode = 'fr'; */
export const database = getDatabase();
export const firestore = getFirestore();
export const functions = getFunctions(undefined, "europe-west1");
export const storage = getStorage();


export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope("email");
googleAuthProvider.setCustomParameters({
	prompt: 'select_account',
	hd: 'reasyedu.com'
})

type Props = {
  authenticationSuccess: (userData: User) => void,
  /* failed: (message: string) => void, */
};

type State = {
};

class Authentication extends React.Component<Props, State> {

  componentDidMount() {
    auth.onAuthStateChanged(async (userData) => {
        /* console.log("[firebaseConfig] User data", userData); */
		if(userData !== null) {
			

			this.props.authenticationSuccess(userData);
			// get user data 

			get(ref(database, `u/${userData.uid}`))
			.then(data => {
				if(!data.val() || !data.val()["e"]) {
					update(ref(database, `u/${userData.uid}`), {
						e: userData.email,
					})
				}
			})
			.catch(err => {
				console.log("[firebaseConfig] error getting user data:", err);
			});

			const nowTimestamp = Math.round(new Date().getTime() / 1000);
			push(ref(database, `l/u/${userData.uid}/a`), {
				t: nowTimestamp,
			})
			.then(() => {
				//console.log("[firebase] a. push success");
			})
			.catch(err => {
				//console.log("[firebase] error logging:", err);
			})
		}
		else {
			console.log("[firebaseConfig] We did not authenticate.");
		}
    });
  }

  render() {
      return (
        <></>
      );
  }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
      authenticationSuccess: (userData: User) => {
        dispatch(authActions.authenticationSuccess(userData));
      },
    }
}

export default connect(null, mapDispatchToProps)(Authentication);