import {
    IonAccordion,
    IonAccordionGroup,
    IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonContent, IonFab, IonFabButton,
    IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonPage, IonPopover, IonSelect, IonSelectOption, IonSpinner, IonTextarea, IonTitle,
    IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import { add, chevronDown, chevronUp } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import MenuTabBar from '../../../common/TabBar/MenuTabBar';
import { auth, functions } from '../../../firebaseConfig';
import { Notification, Organization } from '../store/reducers';
import { doesItHaveAnActiveProduct } from '../store/services';
import "./ReasyNotificationSender.css";

type Props = {
    history: any,
};

type State = {
    notifications: Notification[]
    isLoadingNotifications: boolean,
    showNotificationForm: boolean,
    showUrl: number[],
    selectedType: "offer" | "announcement" | "reminder" | "other" | null,
    title: string,
    body: string,
    url: string,
    expiryDate: number,
    showUserPool: boolean,
    selectedUsers: string[],
    organizations: Organization[],
};

class ReasyNotificationSender extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            notifications: [],
            isLoadingNotifications: false,
            showNotificationForm: false,
            showUrl: [],
            selectedType: null,
            title: '',
            body: '',
            url: '',
            expiryDate: 0,
            showUserPool: false,
            selectedUsers: [],
            organizations: []
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ isLoadingNotifications: true });
                const getNotifications = httpsCallable(functions, "getNotifications");
                getNotifications()
                    .then(res => {
                        console.log(res);

                        if ((res.data as any).notifications) {
                            this.setState({ notifications: (res.data as any).notifications as Notification[] });
                        }
                        else {
                            console.error("[ReasyNotificationSender] error returned from getNotifications cloud function:", res.data);
                        }
                        this.setState({ isLoadingNotifications: false });
                    })
                    .catch(err => {
                        console.error("[ReasyNotificationSender] error calling getNotifications cloud function:", err);
                        this.setState({ isLoadingNotifications: false });
                    })
                const getOrganizations = httpsCallable(functions, "getOrganizations");
                getOrganizations()
                    .then(res => {
                        console.log(res);

                        if ((res.data as any).organizations) {
                            this.setState({ organizations: (res.data as any).organizations as Organization[] });
                        }
                        else {
                            console.error("[ReasyNotificationSender] error returned from getOrganizations cloud function:", res.data);
                        }
                        this.setState({ isLoadingNotifications: false });
                    })
                    .catch(err => {
                        console.error("[ReasyNotificationSender] error calling getOrganizations cloud function:", err);
                        this.setState({ isLoadingNotifications: false });
                    })
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/reasy" />
                        </IonButtons>
                        <IonTitle>
                            Notification Sender {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="reasyLicensesManagerMainGrid">
                        {
                            this.state.isLoadingNotifications &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            this.state.notifications &&
                            this.state.notifications.length === 0 &&
                            <p>
                                Nessuna notifica.
                            </p>
                        }
                        <div className='reasyNotificationHeaderContainer'>

                            <IonButton onClick={() => {
                                this.setState({ showNotificationForm: !this.state.showNotificationForm })
                            }}>
                                New notification
                                <IonIcon icon={this.state.showNotificationForm ? chevronUp : chevronDown} />
                            </IonButton>
                            {this.state.showNotificationForm &&
                                <div className='reasyNotificationForm'>
                                    <IonItem style={{ cursor: 'pointer' }} onClick={() => this.setState({ showUserPool: true })}>
                                        <IonLabel>Users pool</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position='floating'>Type</IonLabel>
                                        <IonSelect value={this.state.selectedType} onIonChange={(e) => this.setState({ selectedType: e.detail.value })}>
                                            <IonSelectOption value='offer'>Offer</IonSelectOption>
                                            <IonSelectOption value='announcement'>Announcement</IonSelectOption>
                                            <IonSelectOption value='reminder'>Reminder</IonSelectOption>
                                            <IonSelectOption value='other'>Other</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position='floating'>Title</IonLabel>
                                        <IonInput
                                            value={this.state.title}
                                            onIonChange={(e) => {
                                                if (e.detail.value?.trim() !== '' && e.detail.value !== null && e.detail.value !== undefined)
                                                    this.setState({ title: e.detail.value })
                                            }}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position='floating'>Body</IonLabel>
                                        <IonTextarea
                                            value={this.state.body}
                                            onIonChange={(e) => {
                                                if (e.detail.value?.trim() !== '' && e.detail.value !== null && e.detail.value !== undefined)
                                                    this.setState({ body: e.detail.value })
                                            }}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position='floating'>Url</IonLabel>
                                        <IonInput
                                            value={this.state.url}
                                            onIonChange={(e) => {
                                                if (e.detail.value?.trim() !== '' && e.detail.value !== null && e.detail.value !== undefined)
                                                    this.setState({ url: e.detail.value })
                                            }}
                                        />
                                    </IonItem>
                                    <IonButton disabled={!this.state.title || !this.state.body || !this.state.selectedType}></IonButton>
                                </div>
                            }
                        </div>
                        <IonItemDivider></IonItemDivider>
                        <div className="reasyLicensesManagerOrganizationsListDiv">
                            {
                                this.state.notifications &&
                                this.state.notifications.map((notification, key) => {
                                    return (
                                        <IonCard
                                            key={notification.id}
                                            className=""
                                        >
                                            <IonCardHeader>
                                                {notification.title}
                                                {(new Date(notification.date * 1000)).toDateString()}
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <p>{notification.body}</p>
                                                {notification.url &&
                                                    <>
                                                        <p
                                                            style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }}
                                                            onClick={() => {
                                                                let urlArr = this.state.showUrl
                                                                urlArr.includes(key) ? urlArr.splice(urlArr.indexOf(key), 1) : urlArr.push(key)
                                                                this.setState({ showUrl: urlArr })
                                                            }
                                                            }>
                                                            Show url
                                                        </p>
                                                        <p
                                                            hidden={!this.state.showUrl.includes(key)}
                                                        >
                                                            {notification.url}
                                                        </p>
                                                        <IonButton
                                                            hidden={!this.state.showUrl.includes(key)}
                                                            onClick={() => window.open(notification.url!, '_blank')}
                                                        >
                                                            Go
                                                        </IonButton>
                                                    </>
                                                }
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                })
                            }
                        </div>
                        <IonPopover
                            className='userPoolPopover'
                            isOpen={this.state.showUserPool}
                            onDidDismiss={() => { this.setState({ showUserPool: false }) }}>
                            <div className='userPoolPopoverDiv'>
                                <IonLabel>Users Pool</IonLabel>
                                <p>Select the users you want to send the notification to</p>
                                <div>
                                    <IonAccordionGroup>
                                        <IonAccordion>
                                            <IonItem slot='header' className={
                                                this.state.selectedUsers.includes('student') ||
                                                    this.state.selectedUsers.includes('teacher') ||
                                                    this.state.selectedUsers.includes("other") ? "selectedByRole" : ''}>
                                                <IonLabel>Select by role</IonLabel>
                                            </IonItem>
                                            <div slot='content'>
                                                <IonItem>
                                                    <IonLabel>Students</IonLabel>
                                                    <IonCheckbox
                                                        checked={this.state.selectedUsers.includes('student')}
                                                        onIonChange={() => {
                                                            let selectedUsers = this.state.selectedUsers
                                                            selectedUsers.includes('student') ? selectedUsers.splice(selectedUsers.indexOf('student'), 1) : selectedUsers.push('student')
                                                            this.setState({ selectedUsers: selectedUsers })
                                                        }} />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel>Teachers</IonLabel>
                                                    <IonCheckbox
                                                        checked={this.state.selectedUsers.includes('teacher')}
                                                        onIonChange={() => {
                                                            let selectedUsers = this.state.selectedUsers
                                                            selectedUsers.includes('teacher') ? selectedUsers.splice(selectedUsers.indexOf('teacher'), 1) : selectedUsers.push('teacher')
                                                            this.setState({ selectedUsers: selectedUsers })
                                                        }} />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel>Others</IonLabel>
                                                    <IonCheckbox
                                                        checked={this.state.selectedUsers.includes('other')}
                                                        onIonChange={() => {
                                                            let selectedUsers = this.state.selectedUsers
                                                            selectedUsers.includes('other') ? selectedUsers.splice(selectedUsers.indexOf('other'), 1) : selectedUsers.push('other')
                                                            this.setState({ selectedUsers: selectedUsers })
                                                        }} />
                                                </IonItem>
                                            </div>
                                        </IonAccordion>
                                    </IonAccordionGroup>
                                    {this.state.organizations && this.state.organizations.length > 0 &&
                                        <IonAccordionGroup>
                                            <IonAccordion>
                                                <IonItem slot='header' className={
                                                    this.state.selectedUsers.filter(u => /^org_.*$/.test(u)).length > 0 ? "selectedByRole" : ''}>
                                                    <IonLabel>Select by organization</IonLabel>
                                                </IonItem>
                                                <div slot='content'>
                                                    {this.state.organizations.map(org => {
                                                        return (
                                                            <IonItem>
                                                                <IonLabel>
                                                                    {org.name}
                                                                </IonLabel>
                                                                <IonCheckbox
                                                                    checked={this.state.selectedUsers.includes(`org_${org.uuid}`)}
                                                                    onIonChange={() => {
                                                                        let selectedUsers = this.state.selectedUsers
                                                                        selectedUsers.includes(`org_${org.uuid}`) ? selectedUsers.splice(selectedUsers.indexOf(`org_${org.uuid}`), 1) : selectedUsers.push(`org_${org.uuid}`)
                                                                        this.setState({ selectedUsers: selectedUsers })
                                                                    }} />
                                                            </IonItem>
                                                        )
                                                    })}
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    }
                                    <IonButton
                                        disabled={this.state.selectedUsers.length === 0}
                                        onClick={() => {
                                            const sendNotification = httpsCallable(functions, 'sendNotification');
                                            sendNotification({
                                                notificationType: this.state.selectedType,
                                                title: this.state.title,
                                                message: this.state.body,
                                                url: this.state.url,
                                                users: this.state.selectedUsers,
                                            })
                                        }}>
                                        Send!
                                    </IonButton>
                                    <p>{JSON.stringify(this.state.selectedUsers)}</p>
                                </div>
                            </div>
                        </IonPopover>
                    </IonGrid>
                </IonContent>

                <MenuTabBar />
            </IonPage >

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyNotificationSender);