import { Product } from "./reducers";

export function fromProductIdToProductName(productId: string): string {
    switch (String(productId).trim()) {
        case "prod_P1s70cOVfiPMjR":
            return "Licenza web";

        case "prod_MTWGRkJLFr6LOG":
            return "Licenza web - TEST";

        case "ai.tech4all.reasy.full.free-trial":
            return "Prova gratuita"; /* TO BE LOCALIZED */

        case "ai.tech4all.reasy.full.free":
            return "Licenza gratuita assegnata dal team di Tech4All"; /* TO BE LOCALIZED */

        case "ai.tech4all.reasy.full.custom":
            return "Licenza personalizzata"; /* TO BE LOCALIZED */

        case "ai.tech4all.reasy.full.monthly":
            return "Abbonamento mensile"; /* TO BE LOCALIZED */

        default:
            break;
    }
    return "";
}

export function doesItHaveAnActiveProduct(products: Product[]): boolean {
    const nowTimestamp = Math.floor((new Date()).getTime()/1000);
    for (let i = 0; i < products.length; i++) {
        if(products[i].expiryTimestamp > nowTimestamp) {
            return true;
        }
    }
    return false;
}