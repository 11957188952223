import {
    IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent,
    IonDatetime, IonGrid, IonHeader, IonIcon, IonLabel, IonLoading,
    IonModal, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption,
    IonSpinner, IonTitle, IonToolbar,
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from '../../../../firebaseConfig';
import { Organization, OrganizationDomain, Product, UserDbInfo } from '../../store/reducers';
import { fromProductIdToProductName } from '../../store/services';
import { arrowDownSharp, arrowUpSharp, pencilOutline, trashSharp } from 'ionicons/icons';
import "./ReasyUserDetails.css";
import LicenseRemovalModal from '../../components/LicenseRemovalModal';

type Props = {
    history: any,
};

type State = {
    orgData: Organization | null,
    orgDomains: OrganizationDomain[] | null,

    userDbInfo: UserDbInfo | null,
    isLoadingUserDbInfo: boolean,

    licenseType: number | null,
    licenseExpiryDate: number | null,
    nowDate: Date,
    fiveYearsFromNow: Date,
    isDateModalOpen: boolean,
    userMailerliteGroups: any[],
    tempUserMailerliteGroups: any[],
    allMailerliteGroups: any[],
    mailerliteImportantGroups: any[],
    initialTempUserMailerliteGroups: any[],
    showSecondaryGroups: boolean,
    isAssignDateModalOpen: boolean,
    assignDate: number | null,
    mailerliteGroupsChanges: {
        added: any[],
        deleted: any[]
    },
    showConfirmationPopover: boolean,
    mailerLiteSubscriberId: string,
    purchaseDate: any

    isLoading: boolean,

    passwordReset: boolean,
    newPassword: string,

    productsEditingMode: boolean,
    selectedProductToRemove: Product | null,
    showRemovalConfirmationPopover: boolean,
};

class ReasyUserDetails extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            orgData: null,
            orgDomains: null,

            userDbInfo: null,
            isLoadingUserDbInfo: false,

            licenseType: null,
            licenseExpiryDate: null,
            nowDate: nowDate,
            fiveYearsFromNow: fiveYearsFromNow,
            isDateModalOpen: false,
            userMailerliteGroups: [],
            tempUserMailerliteGroups: [],
            allMailerliteGroups: [],
            initialTempUserMailerliteGroups: [],
            mailerliteImportantGroups: ["101107758551533469", "101107779014494163", "95481114482706184", "103811791718450364", "101272714622273189", "95481114495289101", "103109141593065084", "103817082758497879", "101272740947822470"],
            showSecondaryGroups: false,
            isAssignDateModalOpen: false,
            assignDate: null,
            mailerliteGroupsChanges: {
                added: [],
                deleted: []
            },
            showConfirmationPopover: false,
            mailerLiteSubscriberId: '',
            purchaseDate: null,

            isLoading: false,
            passwordReset: false,
            newPassword: '',

            productsEditingMode: false,
            selectedProductToRemove: null,
            showRemovalConfirmationPopover: false,
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        console.log("[UserDetails] user uuid:", url.searchParams.get("id"));
        if (url.searchParams.get("id")) {
            this.setState({ isLoadingUserDbInfo: true });

            const getUser = httpsCallable(functions, "getUser");
            getUser({
                userUuid: url.searchParams.get("id"),
            })
                .then((res) => {
                    if ((res.data as any).user) {
                        this.setState({ userDbInfo: (res.data as any).user as UserDbInfo });

                        if (((res.data as any).user as UserDbInfo).organizationUuid) {
                            const getOrganization = httpsCallable(functions, "getOrganization");
                            getOrganization({
                                organizationUuid: ((res.data as any).user as UserDbInfo).organizationUuid,
                            })
                                .then((res) => {
                                    if ((res.data as any).organization) {
                                        this.setState({ orgData: (res.data as any).organization as Organization });
                                    }
                                    else {
                                        console.error("[ReasyUserDetails] error returned by getOrganization cloud function:", res.data);
                                    }
                                })
                                .catch(err => {
                                    console.error("[ReasyUserDetails] error calling getOrganization cloud function:", err);
                                })
                        }
                    }
                    else {
                        console.error("[ReasyUserDetails] error returned from getUser cloud function:", (res.data as any).error);
                    }
                    const getMailerliteGroups = httpsCallable(functions, "getMailerliteGroups")
                    getMailerliteGroups({ email: ((res.data as any).user as UserDbInfo).email })
                        .then((response: any) => {
                            console.log("[getMailerliteGroups] response: ", response);

                            if (response.data.subscriberId === '') {
                                this.setState({ userMailerliteGroups: response.data.userGroups, tempUserMailerliteGroups: response.data.userGroups, allMailerliteGroups: response.data.AllGroups, mailerLiteSubscriberId: response.data.subscriberId, assignDate: new Date(response.data.purchaseDate).getTime() / 1000 })
                            }
                            else {
                                console.log("Mailerlite groups for " + ((res.data as any).user as UserDbInfo).email + ":" + JSON.stringify(response.data.userGroups));
                                this.setState({ userMailerliteGroups: response.data.userGroups, tempUserMailerliteGroups: response.data.userGroups, allMailerliteGroups: response.data.AllGroups, mailerLiteSubscriberId: response.data.subscriberId, assignDate: new Date(response.data.purchaseDate).getTime() / 1000 }, () => {
                                    const initialGroupsArray = this.state.userMailerliteGroups
                                    console.log("VERY INITIAL ", initialGroupsArray);
                                    this.setState({
                                        initialTempUserMailerliteGroups: [...this.state.tempUserMailerliteGroups],
                                    });
                                })
                            }
                            console.log("EEE", new Date(response.data.purchaseDate));
                            console.log("ALL GROUPS: ", response.data.AllGroups);


                        })
                    this.setState({ isLoadingUserDbInfo: false });
                })
                .catch(err => {
                    console.error("[ReasyUserDetails] error calling getUser cloud function:", err);
                    this.setState({ isLoadingUserDbInfo: false });
                })
        }
    }
    findArrayChanges = () => {
        const initialGroups = this.state.initialTempUserMailerliteGroups;
        const currentGroups = this.state.tempUserMailerliteGroups;

        const addedGroups = currentGroups.filter(group => !initialGroups.some(initialGroup => initialGroup.id === group.id));
        const deletedGroups = initialGroups.filter(initialGroup => !currentGroups.some(group => group.id === initialGroup.id));

        console.log("Added Groups:", addedGroups);
        console.log("Deleted Groups:", deletedGroups);
        let changes = {
            added: addedGroups,
            deleted: deletedGroups
        }
        this.setState({ mailerliteGroupsChanges: changes })
        return { addedGroups, deletedGroups };
    }

    fromLanguageToFlag(language?: string): string {
        if (language) {
            if (language.startsWith("it")) {
                return "🇮🇹";
            }
            else if (language.startsWith("es")) {
                return "🇪🇸";
            }
            else if (language.startsWith("en")) {
                return "🇬🇧"
            }
            else if (language.startsWith("fr")) {
                return "🇫🇷"
            }
        }
        return "🏳️‍🌈";
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy"
                            />
                        </IonButtons>
                        <IonTitle>
                            User {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="organizationDetailsMainGrid">

                        {
                            this.state.isLoadingUserDbInfo &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.userDbInfo &&
                            !this.state.isLoadingUserDbInfo &&
                            <div>
                                <p>
                                    Nessun utente selezionato o utente non esistente.
                                </p>
                            </div>
                        }

                        {
                            this.state.userDbInfo &&
                            <>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            User data {/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div>
                                            <p>
                                                Email: <b>{this.state.userDbInfo.email}</b>. {/* TO BE LOCALIZED */}
                                            </p>
                                            {
                                                this.state.orgData &&
                                                <p>
                                                    Organization: <b>{this.state.orgData.name}</b> {/* TO BE LOCALIZED */}
                                                </p>
                                            }
                                            {
                                                this.state.userDbInfo.language &&
                                                <p>
                                                    Language: {this.fromLanguageToFlag(this.state.userDbInfo.language)}
                                                </p>
                                            }
                                        </div>
                                    </IonCardContent>
                                    <IonButton onClick={() => {
                                        if (this.state.userDbInfo && this.state.userDbInfo.email !== null) {
                                            const updateUser = httpsCallable(functions, "updateUser");
                                            updateUser({ userEmail: this.state.userDbInfo?.email })
                                                .then((response: any) => {
                                                    console.log("[Reset user password] response: ", response);
                                                    this.setState({ passwordReset: true, newPassword: response.data.password, })
                                                })
                                                .catch((error: any) => {
                                                    console.log("[Reset user password] error: ", error);
                                                })
                                        }
                                    }}>
                                        Reset user password
                                    </IonButton>
                                    {this.state.passwordReset && this.state.newPassword.length > 0 &&
                                        <p>New password for the user: {this.state.newPassword}</p>
                                    }
                                </IonCard>

                                {
                                    this.state.userDbInfo.products.length > 0 &&
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle className='userDetailProductHeader'>
                                                Assigned products {/* TO BE LOCALIZED */}
                                                <IonIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        this.setState({ productsEditingMode: !this.state.productsEditingMode })
                                                    }}
                                                    icon={pencilOutline}
                                                />
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {
                                                this.state.userDbInfo.products.map(product => {
                                                    return (
                                                        <div className='userDetailProductDiv'>
                                                            <IonButton hidden={!this.state.productsEditingMode} color='danger' onClick={() => {
                                                                this.setState({ selectedProductToRemove: product, showRemovalConfirmationPopover: true })
                                                            }}>
                                                                <IonIcon icon={trashSharp} />
                                                            </IonButton>
                                                            <div
                                                                key={product.uuid}
                                                                className="organizationDetailProductItemDiv"
                                                            >
                                                                <p className="organizationDetailProductItemName">
                                                                    {fromProductIdToProductName(product.productId)}
                                                                </p>

                                                                <p>
                                                                    Expiration on: {(new Date(product.expiryTimestamp * 1000)).toLocaleDateString()}. {/* TO BE LOCALIZED */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                }

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Assign product to user{/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonSelect
                                            value={this.state.licenseType}
                                            placeholder="Select a product type" /* TO BE LOCALIZED */
                                            onIonChange={(e) => {
                                                this.setState({ licenseType: e.detail.value ? e.detail.value : null });
                                            }}
                                        >
                                            <IonSelectOption value={-1}>
                                                Free trial license
                                            </IonSelectOption>
                                            <IonSelectOption value={3}>
                                                Free license assigned by the T4A team ✨
                                            </IonSelectOption>
                                            <IonSelectOption value={4}>
                                                Transfer
                                            </IonSelectOption>
                                        </IonSelect>

                                        <div>
                                            {
                                                !this.state.licenseExpiryDate &&
                                                <IonButton
                                                    fill="clear"
                                                    className="organizationDetailsNewProductExpiryDateButton"
                                                    onClick={() => {
                                                        this.setState({ isDateModalOpen: true });
                                                    }}
                                                >
                                                    Set product expiration date
                                                </IonButton>
                                            }
                                            {
                                                this.state.licenseExpiryDate &&
                                                <p
                                                    onClick={() => {
                                                        this.setState({ isDateModalOpen: true });
                                                    }}
                                                >
                                                    Expiration on: {(new Date(this.state.licenseExpiryDate * 1000)).toLocaleDateString()} {/* TO BE LOCALIZED */}
                                                </p>
                                            }
                                        </div>

                                        <div className="organizationDetailsNewProductAssignButtonDiv">
                                            <IonButton
                                                disabled={
                                                    !this.state.licenseExpiryDate ||
                                                    !this.state.licenseType
                                                }
                                                onClick={() => {
                                                    if (this.state.userDbInfo && this.state.licenseType && this.state.licenseExpiryDate) {
                                                        this.setState({ isLoading: true });
                                                        const assignLicenseToUser = httpsCallable(functions, "assignLicenseToUser");
                                                        assignLicenseToUser({
                                                            userUuid: this.state.userDbInfo.uuid,
                                                            licenseType: this.state.licenseType,
                                                            licenseExpiryDate: this.state.licenseExpiryDate,
                                                        })
                                                            .then(res => {
                                                                if ((res.data as any).ok) {
                                                                    window.location.reload();
                                                                }
                                                                else {
                                                                    console.error("[ReasyUserDetails] error returned from assignLicenseToUser cloud function:", res.data);
                                                                }
                                                                this.setState({ isLoading: false });
                                                            })
                                                            .catch(err => {
                                                                console.error("[ReasyUserDetails] error calling assignLicenseToUser cloud function:", err);
                                                                this.setState({ isLoading: false });
                                                            });
                                                    }
                                                }}
                                            >
                                                Assign product {/* TO BE LOCALIZED */}
                                            </IonButton>
                                        </div>

                                        <div className="reasyUserDetailsAssignOrgBasedOnEmailButtonDiv">
                                            <IonButton
                                                className="reasyUserDetailsAssignOrgBasedOnEmailButton"
                                                color="medium"
                                                disabled={!this.state.userDbInfo}
                                                fill="clear"
                                                size="small"
                                                onClick={() => {
                                                    if (this.state.userDbInfo) {
                                                        const assignOrganizationBasedOnUserEmailDomain = httpsCallable(functions, "assignOrganizationBasedOnUserEmailDomain");
                                                        assignOrganizationBasedOnUserEmailDomain({
                                                            userUuid: this.state.userDbInfo.uuid,
                                                        })
                                                            .then(res => {
                                                                if ((res.data as any).ok) {
                                                                    window.location.reload();
                                                                }
                                                                else {
                                                                    console.error("[ReasyUserDetails] error returned by assignOrganizationBasedOnUserEmailDomain cloud function:", res.data);
                                                                }
                                                            })
                                                            .catch(err => {
                                                                console.error("[ReasyUserDetails] error calling assignOrganizationBasedOnUserEmailDomain cloud function:", err);
                                                            })
                                                    }
                                                }}
                                            >
                                                Checks whether an organization license<br />can be assigned to the user {/* TO BE LOCALIZED */}
                                            </IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                                <LicenseRemovalModal
                                    history={this.props.history}
                                    isOpen={this.state.showRemovalConfirmationPopover}
                                    onDidDismiss={() => this.setState({ showRemovalConfirmationPopover: false, selectedProductToRemove: null })}
                                    product={this.state.selectedProductToRemove}
                                    orgData={this.state.orgData}
                                />
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Mailerlite groups{/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                        <IonCardSubtitle hidden={this.state.mailerLiteSubscriberId !== ''} style={{ color: 'red' }}>
                                            User is not subscribed to mailerlite, clicking on "Save groups" will create and assign user to selected groups
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardSubtitle className='mailGroupsSubTitle'>Primary groups</IonCardSubtitle>
                                    <IonCardContent>
                                        <div className='mailGroupsDiv'>
                                            {
                                                this.state.allMailerliteGroups.filter(group => this.state.mailerliteImportantGroups.includes(group.id)).map((group, i) => {
                                                    return (
                                                        <div className='mailImportantGroups' key={i}>
                                                            <IonCard
                                                                key={i}
                                                                onClick={() => {
                                                                    let userGroups = this.state.tempUserMailerliteGroups
                                                                    if (this.state.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0) {
                                                                        this.setState(prevState => {
                                                                            const updatedGroups = [...prevState.tempUserMailerliteGroups];
                                                                            const groupIndex = updatedGroups.findIndex(userGroup => userGroup.id === group.id);
                                                                            if (groupIndex !== -1) {
                                                                                updatedGroups.splice(groupIndex, 1);
                                                                            }
                                                                            return { tempUserMailerliteGroups: updatedGroups };
                                                                        }, () => {
                                                                            this.findArrayChanges()
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.setState(prevState => {
                                                                            const updatedGroups = [...prevState.tempUserMailerliteGroups];
                                                                            if (!updatedGroups.some(userGroup => userGroup.id === group.id)) {
                                                                                updatedGroups.push(group);
                                                                            }
                                                                            return { tempUserMailerliteGroups: updatedGroups };
                                                                        }, () => {
                                                                            this.findArrayChanges()
                                                                        });
                                                                    }
                                                                }}
                                                                className={this.state.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0 ? 'mailGroupCard mailGroupChecked' : 'mailGroupCard'}
                                                            >
                                                                <IonLabel>{group.name}</IonLabel>
                                                            </IonCard>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <IonCardSubtitle className='mailGroupsSubTitle'>Subgroups</IonCardSubtitle>
                                        <IonButton
                                            onClick={() => { this.setState({ showSecondaryGroups: !this.state.showSecondaryGroups }) }}>
                                            {this.state.showSecondaryGroups ? 'Hide' : 'Show'}
                                            <IonIcon slot='end' icon={this.state.showSecondaryGroups ? arrowUpSharp : arrowDownSharp} />
                                        </IonButton>
                                        <div className='mailGroupsDiv' hidden={!this.state.showSecondaryGroups}>
                                            {
                                                this.state.allMailerliteGroups.filter(group => !this.state.mailerliteImportantGroups.includes(group.id)).map((group, i) => {
                                                    return (
                                                        <div className='mailDefaultGroups' key={i}>
                                                            <IonCard
                                                                key={i}
                                                                onClick={() => {
                                                                    let userGroups = this.state.tempUserMailerliteGroups
                                                                    if (this.state.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0) {
                                                                        this.setState(prevState => {
                                                                            const updatedGroups = [...prevState.tempUserMailerliteGroups];
                                                                            const groupIndex = updatedGroups.findIndex(userGroup => userGroup.id === group.id);
                                                                            if (groupIndex !== -1) {
                                                                                updatedGroups.splice(groupIndex, 1);
                                                                            }
                                                                            return { tempUserMailerliteGroups: updatedGroups };
                                                                        }, () => {
                                                                            this.findArrayChanges()
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.setState(prevState => {
                                                                            const updatedGroups = [...prevState.tempUserMailerliteGroups];
                                                                            if (!updatedGroups.some(userGroup => userGroup.id === group.id)) {
                                                                                updatedGroups.push(group);
                                                                            }
                                                                            return { tempUserMailerliteGroups: updatedGroups };
                                                                        }, () => {
                                                                            this.findArrayChanges()
                                                                        });
                                                                    }
                                                                }}
                                                                className={this.state.tempUserMailerliteGroups.filter(userGroup => group.id === userGroup.id).length > 0 ? 'mailGroupCard mailGroupChecked' : 'mailGroupCard'}
                                                            >
                                                                <IonLabel>{group.name}</IonLabel>
                                                            </IonCard>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            {
                                                !this.state.assignDate &&
                                                <IonButton
                                                    fill="clear"
                                                    className="organizationDetailsNewProductExpiryDateButton"
                                                    onClick={() => {
                                                        this.setState({ isAssignDateModalOpen: true });
                                                    }}
                                                >
                                                    Set purchase date
                                                </IonButton>
                                            }
                                            {
                                                this.state.assignDate &&
                                                <p
                                                    onClick={() => {
                                                        this.setState({ isAssignDateModalOpen: true });
                                                    }}
                                                >
                                                    Purchased on: {(new Date(this.state.assignDate * 1000)).toLocaleDateString()} {/* TO BE LOCALIZED */}
                                                </p>
                                            }
                                        </div>

                                        <div className="organizationDetailsNewProductAssignButtonDiv">
                                            {this.state.assignDate === null &&
                                                <p style={{ textAlign: 'center' }}>⚠️ Set a license purchase date ⚠️</p>
                                            }
                                            {this.state.mailerliteGroupsChanges.added.length === 0 &&
                                                this.state.mailerliteGroupsChanges.deleted.length === 0 &&
                                                <p style={{ textAlign: 'center' }}>⚠️ There were no changes to the user's groups ⚠️</p>
                                            }
                                            <IonButton
                                                // disabled={
                                                //     this.state.assignDate === null ||
                                                //     (this.state.mailerliteGroupsChanges.added.length === 0 &&
                                                //         this.state.mailerliteGroupsChanges.deleted.length === 0)
                                                // }
                                                onClick={() => {
                                                    const { addedGroups, deletedGroups } = this.findArrayChanges();
                                                    console.log("Added Groups:", addedGroups);
                                                    console.log("Deleted Groups:", deletedGroups);
                                                    let changes = {
                                                        added: addedGroups,
                                                        deleted: deletedGroups
                                                    }
                                                    this.setState({ mailerliteGroupsChanges: changes, showConfirmationPopover: true })
                                                }}
                                            >
                                                Save groups {/* TO BE LOCALIZED */}
                                            </IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Accesses {/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {
                                            this.state.userDbInfo.accesses.map((access, i) => {
                                                return (
                                                    <div key={i}>
                                                        {(new Date(access * 1000)).toLocaleString()}
                                                    </div>
                                                )
                                            })
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </>
                        }

                    </IonGrid>
                </IonContent>

                <IonModal
                    isOpen={this.state.isDateModalOpen}
                    onDidDismiss={() => {
                        this.setState({ isDateModalOpen: false });
                    }}
                >
                    <IonContent>
                        <IonDatetime
                            min={this.state.nowDate.toISOString().split("T")[0]}
                            max={this.state.fiveYearsFromNow.toISOString().split("T")[0]}
                            onIonChange={e => {
                                this.setState({ licenseExpiryDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                            }}
                        />

                        <IonButton
                            onClick={() => {
                                this.setState({ isDateModalOpen: false });
                            }}
                        >
                            Done {/* TO BE LOCALIZED */}
                        </IonButton>

                    </IonContent>
                </IonModal>
                <IonModal
                    isOpen={this.state.isAssignDateModalOpen}
                    onDidDismiss={() => {
                        this.setState({ isAssignDateModalOpen: false });
                    }}
                >
                    <IonContent>
                        <IonDatetime
                            max={this.state.fiveYearsFromNow.toISOString().split("T")[0]}
                            onIonChange={e => {
                                this.setState({ assignDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                            }}
                        />

                        <IonButton
                            onClick={() => {
                                this.setState({ isAssignDateModalOpen: false });
                            }}
                        >
                            Done {/* TO BE LOCALIZED */}
                        </IonButton>

                    </IonContent>
                </IonModal>
                <IonPopover
                    className='changesPopover'
                    isOpen={this.state.showConfirmationPopover}
                    onDidDismiss={() => this.setState({ showConfirmationPopover: false })}
                >
                    <div className='changesPopoverMainDiv'>
                        <p className='changesPopoverTitle'>Would you like to confirm the changes?</p>
                        <div>
                            <p className='changesPopoverSubTitle'>Mailerlite groups</p>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <p className='changesPopopverAddedGroupsText'>Added groups</p>
                                        {this.state.mailerliteGroupsChanges.added.map((addedGroup, i) => {
                                            return (
                                                <IonRow key={i}>
                                                    <IonLabel>{addedGroup.name}</IonLabel>
                                                </IonRow>
                                            )
                                        })}
                                    </IonCol>
                                    <IonCol>
                                        <p className='changesPopopverDeletedGroupsText'>Removed groups</p>

                                        {this.state.mailerliteGroupsChanges.deleted.map((deletedGroup, i) => {
                                            return (
                                                <IonRow key={i}>
                                                    <IonLabel>{deletedGroup.name}</IonLabel>
                                                </IonRow>
                                            )
                                        })}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                        <div>
                            <p className='changesPopoverSubTitle'>Purchase date</p>
                            {this.state.assignDate &&
                                <p>Purchase date: <b>{(new Date(this.state.assignDate * 1000)).toLocaleDateString()} {/* TO BE LOCALIZED */}</b></p>
                            }
                        </div>
                        <div className='changesPopoverBtnDiv'>
                            <IonButton
                                color='success'
                                onClick={() => {
                                    const changeMailerliteGroups = httpsCallable(functions, "changeMailerliteGroups")
                                    changeMailerliteGroups({ email: this.state.userDbInfo?.email, groupChanges: this.state.mailerliteGroupsChanges, subscriberId: this.state.mailerLiteSubscriberId, purchaseDate: this.state.assignDate ? this.state.assignDate * 1000 : null })
                                        .then((response: any) => {
                                            console.log("CHANGED MAILERLITE GROUPS: ", response);
                                            this.setState({ showConfirmationPopover: false })
                                            window.location.reload()
                                        })
                                }}
                            >
                                Confirm
                            </IonButton>
                        </div>
                    </div>
                </IonPopover>

                <IonLoading isOpen={this.state.isLoading} />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyUserDetails);