import {
    IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
    IonCardHeader, IonCardTitle, IonContent, IonDatetime, IonGrid, IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal, IonPage, IonSelect, IonSelectOption, IonSpinner, IonTitle, IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from '../../../../firebaseConfig';
import { Organization, OrganizationDomain, Product } from '../../store/reducers';
import { fromProductIdToProductName } from '../../store/services';
import "./OrganizationDetails.css";
import { pencilOutline, trashSharp } from 'ionicons/icons';
import LicenseRemovalModal from '../../components/LicenseRemovalModal';

type Props = {
    history: any,
};

type State = {
    orgData: Organization | null,
    orgDomains: OrganizationDomain[] | null,
    isLoadingOrgData: boolean,

    licenseType: number | null,
    licenseExpiryDate: number | null,
    nowDate: Date,
    fiveYearsFromNow: Date,
    isDateModalOpen: boolean,

    orgEmailDomain: string | null,

    isLoading: boolean,

    uploadedFile: any,

    productsEditingMode: boolean,
    selectedProductToRemove: Product | null,
    showRemovalConfirmationPopover: boolean,
};

class OrganizationDetails extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            orgData: null,
            orgDomains: null,
            isLoadingOrgData: false,

            licenseType: null,
            licenseExpiryDate: null,
            nowDate: nowDate,
            fiveYearsFromNow: fiveYearsFromNow,
            isDateModalOpen: false,

            orgEmailDomain: null,

            isLoading: false,

            uploadedFile: "",

            productsEditingMode: false,
            selectedProductToRemove: null,
            showRemovalConfirmationPopover: false,
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        console.log("[OrganizationDetails] organization uuid:", url.searchParams.get("id"));
        if (url.searchParams.get("id")) {
            this.setState({ isLoadingOrgData: true });
            const getOrganization = httpsCallable(functions, "getOrganization");
            getOrganization({
                organizationUuid: url.searchParams.get("id"),
            })
                .then((res) => {
                    console.log("[OrganizationDetails] data returned:", res.data);
                    if ((res.data as any).organization) {
                        this.setState({ orgData: (res.data as any).organization as Organization });
                        const getOrganizationEmailDomains = httpsCallable(functions, "getOrganizationEmailDomains");
                        getOrganizationEmailDomains({
                            organizationUuid: ((res.data as any).organization as Organization).uuid,
                        })
                            .then(res => {
                                if ((res.data as any).domains) {
                                    this.setState({ orgDomains: (res.data as any).domains as OrganizationDomain[] });
                                }
                                else {
                                    console.error("[OrganizationDetails] error returned from getOrganizationEmailDomains cloud function:", res.data)
                                }
                            })
                            .catch(err => {
                                console.error("[OrganizationDetails] error calling getOrganizationEmailDomains cloud function:", err);
                            })
                    }
                    else {
                        console.error("[OrganizationDetails] error returned from getOrganization cloud function:", (res.data as any).error);
                    }
                    this.setState({ isLoadingOrgData: false });
                })
                .catch(err => {
                    console.error("[OrganizationDetails] error calling getOrganization cloud function:", err);
                    this.setState({ isLoadingOrgData: false });
                })
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy/licenses-manager"
                            />
                        </IonButtons>
                        <IonTitle>
                            Organization {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="organizationDetailsMainGrid">

                        {
                            this.state.isLoadingOrgData &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.orgData &&
                            !this.state.isLoadingOrgData &&
                            <div>
                                <p>
                                    Nessuna organizzazione selezionata o organizzazione non esistente.
                                </p>
                            </div>
                        }

                        {
                            this.state.orgData &&
                            <>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Dati cliente "{this.state.orgData.name}" {/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div>
                                            <p>
                                                Cliente dal {(new Date(this.state.orgData.createdAt * 1000)).toLocaleDateString()}. {/* TO BE LOCALIZED */}
                                            </p>
                                        </div>
                                        <div>
                                            <IonSelect value={this.state.orgData.type} onIonChange={(e) => {
                                                const updateOrgData = httpsCallable(functions, 'updateOrgData');
                                                updateOrgData({
                                                    organizationUuid: this.state.orgData!.uuid,
                                                    type: e.detail.value,
                                                })
                                            }}>
                                                <IonSelectOption value='school'>
                                                    <IonLabel>Scuola</IonLabel>
                                                </IonSelectOption>
                                                <IonSelectOption value='publisher'>
                                                    <IonLabel>Editore</IonLabel>
                                                </IonSelectOption>
                                                <IonSelectOption value='company'>
                                                    <IonLabel>Azienda</IonLabel>
                                                </IonSelectOption>
                                            </IonSelect>
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                                {
                                    this.state.orgData.products.length > 0 &&
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle className='organizationDetailProductHeader'>
                                                Prodotti assegnati {/* TO BE LOCALIZED */}
                                                <IonIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        this.setState({ productsEditingMode: !this.state.productsEditingMode })
                                                    }}
                                                    icon={pencilOutline}
                                                />
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {
                                                this.state.orgData.products.map(product => {
                                                    return (
                                                        <div className='organizationDetailProductDiv'>
                                                            <IonButton hidden={!this.state.productsEditingMode} color='danger' onClick={() => {
                                                                this.setState({ selectedProductToRemove: product, showRemovalConfirmationPopover: true })
                                                            }}>
                                                                <IonIcon icon={trashSharp} />
                                                            </IonButton>
                                                            <div key={product.uuid} className="organizationDetailProductItemDiv">
                                                                <p className="organizationDetailProductItemName">{fromProductIdToProductName(product.productId)}</p>
                                                                <p>Scadenza il: {(new Date(product.expiryTimestamp * 1000)).toLocaleDateString()}. {/* TO BE LOCALIZED */}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                }

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Assegna prodotto all'organizzazione{/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonSelect
                                            value={this.state.licenseType}
                                            placeholder="Seleziona una tipologia di prodotto" /* TO BE LOCALIZED */
                                            onIonChange={(e) => {
                                                this.setState({ licenseType: e.detail.value ? e.detail.value : null });
                                            }}
                                        >
                                            <IonSelectOption value={-1}>
                                                Licenza di prova gratuita
                                            </IonSelectOption>
                                            <IonSelectOption value={3}>
                                                Licenza gratuita assegnata dal team di T4A ✨
                                            </IonSelectOption>
                                            <IonSelectOption value={4}>
                                                Bonifico
                                            </IonSelectOption>
                                        </IonSelect>

                                        <div>
                                            {
                                                !this.state.licenseExpiryDate &&
                                                <IonButton
                                                    fill="clear"
                                                    className="organizationDetailsNewProductExpiryDateButton"
                                                    onClick={() => {
                                                        this.setState({ isDateModalOpen: true });
                                                    }}
                                                >
                                                    Imposta data di scadenza del prodotto
                                                </IonButton>
                                            }
                                            {
                                                this.state.licenseExpiryDate &&
                                                <p
                                                    onClick={() => {
                                                        this.setState({ isDateModalOpen: true });
                                                    }}
                                                >
                                                    Scadenza il: {(new Date(this.state.licenseExpiryDate * 1000)).toLocaleDateString()} {/* TO BE LOCALIZED */}
                                                </p>
                                            }
                                        </div>

                                        <div className="organizationDetailsNewProductAssignButtonDiv">
                                            <IonButton
                                                disabled={
                                                    !this.state.licenseExpiryDate ||
                                                    !this.state.licenseType
                                                }
                                                onClick={() => {
                                                    if (this.state.orgData && this.state.licenseType && this.state.licenseExpiryDate) {
                                                        this.setState({ isLoading: true });
                                                        const assignLicenseToOrganization = httpsCallable(functions, "assignLicenseToOrganization");
                                                        assignLicenseToOrganization({
                                                            organizationUuid: this.state.orgData.uuid,
                                                            licenseType: this.state.licenseType,
                                                            licenseExpiryDate: this.state.licenseExpiryDate,
                                                        })
                                                            .then(res => {
                                                                if ((res.data as any).ok) {
                                                                    window.location.reload();
                                                                }
                                                                else {
                                                                    console.error("[OrganizationDetails] error returned from assignLicenseToOrganization cloud function:", res.data);
                                                                }
                                                                this.setState({ isLoading: false });
                                                            })
                                                            .catch(err => {
                                                                console.error("[OrganizationDetails] error calling assignLicenseToOrganization cloud function:", err);
                                                                this.setState({ isLoading: false });
                                                            });
                                                    }
                                                }}
                                            >
                                                Add product {/* TO BE LOCALIZED */}
                                            </IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Email domains {/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>

                                        {
                                            this.state.orgDomains &&
                                            <div>
                                                {
                                                    this.state.orgDomains.length === 0 &&
                                                    <p>
                                                        Nessun dominio email associato all'organizzazione. {/* TO BE LOCALIZED */}
                                                    </p>
                                                }

                                                <IonList>
                                                    {
                                                        this.state.orgDomains &&
                                                        this.state.orgDomains.map(orgDomain => {
                                                            return (
                                                                <IonItem key={orgDomain.uuid}>
                                                                    {orgDomain.domain}
                                                                </IonItem>
                                                            )
                                                        })
                                                    }
                                                </IonList>
                                            </div>
                                        }


                                        <div>
                                            <IonInput
                                                placeholder="Organization email domain, without @" /* TO BE LOCALIZED */
                                                value={this.state.orgEmailDomain}
                                                onIonChange={(e) => {
                                                    this.setState({ orgEmailDomain: e.detail.value ? e.detail.value : null });
                                                }}
                                            />
                                            <div className="organizationDetailsEmailDomainsAssignButtonDiv">
                                                <IonButton
                                                    disabled={
                                                        !this.state.orgEmailDomain
                                                    }
                                                    onClick={() => {
                                                        if (this.state.orgData) {
                                                            this.setState({ isLoading: true });
                                                            const assignEmailDomainToOrganization = httpsCallable(functions, "assignEmailDomainToOrganization");
                                                            assignEmailDomainToOrganization({
                                                                organizationUuid: this.state.orgData.uuid,
                                                                organizationEmailDomain: this.state.orgEmailDomain,
                                                            })
                                                                .then(res => {
                                                                    if ((res.data as any).ok) {
                                                                        window.location.reload();
                                                                    }
                                                                    else {
                                                                        console.error("[OrganizationDetails] error returned from assignEmailDomainToOrganization cloud function:", res.data);
                                                                    }
                                                                    this.setState({ isLoading: false });
                                                                })
                                                                .catch(err => {
                                                                    console.error("[OrganizationDetails] error calling assignEmailDomainToOrganization cloud function:", err);
                                                                    this.setState({ isLoading: false });
                                                                });
                                                        }
                                                    }}
                                                >
                                                    Assegna dominio all'organizzazione{/* TO BE LOCALIZED */}
                                                </IonButton>
                                            </div>
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                                <LicenseRemovalModal
                                    history={this.props.history}
                                    isOpen={this.state.showRemovalConfirmationPopover}
                                    onDidDismiss={() => this.setState({ showRemovalConfirmationPopover: false, selectedProductToRemove: null })}
                                    product={this.state.selectedProductToRemove}
                                    orgData={this.state.orgData}
                                />
                            </>
                        }


                    </IonGrid>
                </IonContent>

                <IonModal
                    isOpen={this.state.isDateModalOpen}
                    onDidDismiss={() => {
                        this.setState({ isDateModalOpen: false });
                    }}
                >
                    <IonContent>
                        <IonDatetime
                            min={this.state.nowDate.toISOString().split("T")[0]}
                            max={this.state.fiveYearsFromNow.toISOString().split("T")[0]}
                            onIonChange={e => {
                                this.setState({ licenseExpiryDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                            }}
                        />

                        <IonButton
                            onClick={() => {
                                this.setState({ isDateModalOpen: false });
                            }}
                        >
                            Fatto {/* TO BE LOCALIZED */}
                        </IonButton>

                    </IonContent>
                </IonModal>
                <IonLoading
                    isOpen={this.state.isLoading}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);