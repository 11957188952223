import {
    IonBackButton, IonButtons, IonContent, IonFab, IonFabButton,
    IonGrid, IonHeader, IonIcon, IonPage, IonSpinner, IonTitle,
    IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import { add } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import MenuTabBar from '../../../common/TabBar/MenuTabBar';
import { auth, functions } from '../../../firebaseConfig';
import { Organization } from '../store/reducers';
import { doesItHaveAnActiveProduct } from '../store/services';
import "./ReasyLicensesManager.css";

type Props = {
    history: any,
};

type State = {
    orgs: Organization[] | null,

    isLoadingOrganizations: boolean,
};

class ReasyLicensesManager extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            orgs: null,

            isLoadingOrganizations: false,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if(userData) {
                this.setState({ isLoadingOrganizations: true });
                const getOrganizations = httpsCallable(functions, "getOrganizations");
                getOrganizations()
                .then(res => {
                    if((res.data as any).organizations) {
                        this.setState({ orgs: (res.data as any).organizations as Organization[]});
                    }
                    else {
                        console.error("[ReasyLicensesManager] error returned from getOrganizations cloud function:", res.data);
                    }
                    this.setState({ isLoadingOrganizations: false });
                })
                .catch(err => {
                    console.error("[ReasyLicensesManager] error calling getOrganizations cloud function:", err);
                    this.setState({ isLoadingOrganizations: false });
                })
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/reasy" />
                        </IonButtons>
                        <IonTitle>
                            Reasy licenses management {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="reasyLicensesManagerMainGrid">
                        {
                            this.state.isLoadingOrganizations &&
                            <div>
                                <IonSpinner/>
                            </div>
                        }

                        {
                            this.state.orgs &&
                            this.state.orgs.length === 0 &&
                            <p>
                                Nessuna organizzazione presente.
                            </p>
                        }
                        
                        <div className="reasyLicensesManagerOrganizationsListDiv">
                            {
                                this.state.orgs &&
                                this.state.orgs.map(org => {return(
                                    <div
                                        key={org.uuid}
                                        className="reasyLicensesManagerOrganizationItem"
                                        onClick={() => {
                                            this.props.history.push(`/reasy/licenses-manager/organization?id=${org.uuid}`);
                                        }}
                                    >
                                        <p className="reasyLicensesManagerOrganizationItemName">
                                            {org.name}
                                        </p>
                                        <p>{doesItHaveAnActiveProduct(org.products) ? "🟢" : "🔴"}</p>
                                    </div>
                                )})
                            }
                        </div>
                    </IonGrid>

                    <IonFab vertical="bottom" horizontal="end" slot="fixed">
                        <IonFabButton
                            onClick={() => {
                                this.props.history.push("/reasy/licenses-manager/new-organization");
                            }}
                        >
                            <IonIcon icon={add} />
                        </IonFabButton>
                    </IonFab>
                </IonContent>

                <MenuTabBar />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyLicensesManager);