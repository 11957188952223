import {
    IonButton,
    IonModal
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { fromProductIdToProductName } from '../store/services';
import "../pages/licenses/OrganizationDetails.css";
import "./LicenseRemovalModal.css";
import { Organization, Product } from '../store/reducers';

type Props = {
    history: any,
    isOpen: boolean,
    product: Product | null,
    orgData: Organization | null

    onDidDismiss: () => void;
};

type State = {

};

class LicenseRemovalModal extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (

            <IonModal
                className="productRemovalConfirmationModal"
                isOpen={this.props.isOpen}
                initialBreakpoint={1}
                breakpoints={[0, 1]}
                onDidDismiss={() => this.props.onDidDismiss()}
            >
                <div className='productRemovalConfirmationModalDiv'>
                    <div className='productRemovalConfirmationHeader'>
                        <p>Sei sicuro di voler rimuovere il seguente prodotto?</p>
                    </div>
                    <div className='productRemovalConfirmationContent'>
                        {this.props.product &&
                            <div key={this.props.product.uuid} className="productRemovalProductItemDiv">
                                <p className="organizationDetailProductItemName">{fromProductIdToProductName(this.props.product.productId)}</p>
                                <p>Scadenza il: {(new Date(this.props.product.expiryTimestamp * 1000)).toLocaleDateString()}. {/* TO BE LOCALIZED */}</p>
                            </div>
                        }
                        <IonButton
                            onClick={() => {
                                if (this.props.orgData) {
                                    /* 2024_MIGRATION */
                                    /* TODO CALL ENDPOINT TO REMOVE LICENSE FROM ORGANIZATION */
                                }
                            }}
                            color='danger'
                        >
                            ELIMINA
                        </IonButton>
                    </div>
                </div>
            </IonModal>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseRemovalModal);