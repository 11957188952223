import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonContent, IonGrid, IonHeader, IonInput, IonPage, IonTitle, IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from '../../../../firebaseConfig';

type Props = {
    history: any,
};

type State = {
    orgName: string | null,
};

class OrganizationCreation extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            orgName: null,
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy/licenses-manager"
                            />
                        </IonButtons>
                        <IonTitle>
                            Organization creation {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="reasySummaryMainGrid">
                        
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>
                                    New organization {/* TO BE LOCALIZED */}
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>

                                <IonInput
                                    value={this.state.orgName}
                                    placeholder="Organization name" /* TO BE LOCALIZED */
                                    onIonChange={e => {
                                        this.setState({orgName: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <IonButton
                                    onClick={() => {
                                        const createOrganization = httpsCallable(functions, "createOrganization");
                                        createOrganization({
                                            organizationName: this.state.orgName,
                                        })
                                        .then((res) => {
                                            if((res.data as any).organizationUuid) {
                                                this.props.history.push(`/reasy/licenses-manager/organization?id=${(res.data as any).organizationUuid}`);
                                            }
                                            else {
                                                console.error("[OrganizationCreation] error creating organization:", res.data);
                                            }
                                        })
                                        .catch(err => {
                                            console.error("[OrganizationCreation] error calling createOrganization cloud function:", err);
                                        })
                                    }}
                                >
                                    Create organization
                                </IonButton>

                            </IonCardContent>

                        </IonCard>
                    </IonGrid>
                </IonContent>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCreation);