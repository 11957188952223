import {
    
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonPage, IonTitle, IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import { copy } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import MenuTabBar from '../../../common/TabBar/MenuTabBar';
import { functions } from '../../../firebaseConfig';
import "./ReasyUserCreation.css";

type Props = {
    history: any,
};

type State = {
    newUserEmail: string | undefined,
    userUuid: string | undefined,
    userPassword: string | undefined,
};

class ReasyUserCreation extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            newUserEmail: undefined,
            userUuid: undefined,
            userPassword: undefined,
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/reasy"
                            />
                        </IonButtons>
                        <IonTitle>
                            New Reasy user creation {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="reasySummaryMainGrid">
                        <IonInput
                            placeholder="User email" /* TO BE LOCALIZED */
                            type="email"
                            onIonChange={(e) => {
                                this.setState({ newUserEmail: e.detail.value ? e.detail.value.trim() : undefined });
                            }}
                        />

                        <IonButton
                            disabled={
                                !this.state.newUserEmail
                            }
                            onClick={() => {
                                httpsCallable(functions, "createReasyUser")({
                                    userEmail: this.state.newUserEmail,
                                })
                                .then(res => {
                                    console.log("[ReasyUserCreation] res:", res);
                                    if((res.data as any).error) {
                                        console.error("[ReasyUserCreation] error creating new user:", res.data);
                                    }
                                    else {
                                        if((res.data as any).uuid) {
                                            this.setState({
                                                userUuid: (res.data as any).uuid,
                                            })
                                        }

                                        if((res.data as any).password) {
                                            this.setState({
                                                userPassword: (res.data as any).password,
                                            })
                                        }
                                    }
                                })
                                .catch(err => {
                                    console.error("[ReasyUserCreation] error calling createReasyUser cloud function:", err);
                                });
                            }}
                        >
                            Create user {/* TO BE LOCALIZED */}
                        </IonButton>

                        {
                            this.state.userPassword &&
                            <div className="reasyUserCreationPasswordDiv">
                                <p className="reasyUserCreationPasswordHint">
                                    This is the password generated for the user. Copy it now, you will not be able to get it again.
                                    Remember to send it to the user.
                                </p>
                                <div className="reasyUserCreationPasswordTextAndButtonDiv">
                                    <p className="reasyUserCreationPasswordText">
                                        {this.state.userPassword}
                                    </p>
                                    <IonButton
                                        color="medium"
                                        onClick={() => {
                                            if(this.state.userPassword) {
                                                navigator.clipboard.writeText(this.state.userPassword);
                                            }
                                        }}
                                    >
                                        <IonIcon
                                            icon={copy}
                                        />
                                    </IonButton>
                                </div>
                            </div>
                        }

                        {
                            this.state.userUuid &&
                            <IonButton
                                onClick={() => {
                                    this.props.history.push(`/reasy/user?id=${this.state.userUuid}`);
                                }}
                            >
                                Go to the user management page {/* TO BE LOCALIZED */}
                            </IonButton>
                        }
                      
                    </IonGrid>
                </IonContent>

                <MenuTabBar />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyUserCreation);